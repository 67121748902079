<script>
import { toRef, ref } from 'vue'
import { debounce } from 'lodash'

export default {
  props: {
    container: {
      type: String,
      default: ''
    },
    pagination: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const loading     = ref(false)
    const initialC    = toRef(props, 'container')
    const initialP    = toRef(props, 'pagination')
    const container   = ref(initialC.value)
    const pagination  = ref(initialP.value)

    const loadNext = () => {
      loading.value     = true
      const pagination  = document.getElementById(pagination.value)
      const next        = pagination.dataset.next
      const request     = new XMLHttpRequest()

      request.onreadystatechange = function success() {
        if (request.readyState === 4 && request.status === 200) {
          const newContainer  = request.responseXML.getElementById(container.value)
          const newPagination = request.responseXML.getElementById(pagination.value)
          document.getElementById(container.value).innerHTML   += newContainer.innerHTML
          if (newPagination.dataset.next) {
            pagination.dataset.next = newPagination.dataset.next
          } else {
            pagination.remove()
          }
          loading.value  = false
        }
      };

      request.open('GET', next)
      request.responseType = 'document'
      request.send()
    }
    
    return () => slots.default({
      loading: loading.value,
      loadMore: debounce(() => loadNext(), 500)
    })
  }
}
</script>