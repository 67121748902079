<script>
import { toRef, ref } from 'vue'
import { map, pickBy } from 'lodash'
import { onMounted } from 'vue'

export default {
  props: {
    charts: {
      type: Object,
      default: []
    }
  },
  setup(props, { slots }) {
    const initialC    = toRef(props, 'charts')
    const charts      = ref(initialC.value)
    const chart       = ref([])

    const changeHead  = (str) => {
        const strs = str.split(/(?=[A-Z])/)
        strs[0] = strs[0].toUpperCase()
        return strs.join(' ')
    }

    const checkContent = (index, str) => {
      if ( index === 0) {
        if (isNaN(str)) {
          if (str.split('').length > 4) {
            return 'font-medium !text-left'
          }
        }
      }
    }

    return () => slots.default({
      chart: charts.value,
      changeHead,
      checkContent
    })
  }
}
</script>