<script>
import { toRef, ref } from 'vue'
import { filter } from 'lodash'
import { useStore } from '../../store'

export default {
  props: {
    artist_id: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const store       = useStore()
    const artistId    = toRef(props, 'artist_id')
    const loading     = ref(false)
    const albums      = ref(null)
    const singles     = ref(null)
    const getAlbums   = (id, qty) => {
      loading.value = true

      store.spotifyAPI({
        method: 'get',
        url: 'https://api.spotify.com/v1/artists/' + artistId.value + '/albums?include_groups=album,single&limit=50'
      })
      .then((response) => {
        if ( response.data.items.length > 0 ) {
          albums.value  = filter( response.data.items, ({total_tracks}) => total_tracks > 5 )
          singles.value = filter( response.data.items, ({total_tracks}) => total_tracks < 6 )
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
    }

    getAlbums()
    
    return () => slots.default({
      loading: loading.value,
      albums: albums.value,
      singles: singles.value
    })
  }
}
</script>