<script>
import { toRef, ref, onMounted } from 'vue'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 350
    }
  },
  setup(props, { slots }) {
    const active      = toRef(props, 'active')
    const speed       = toRef(props, 'speed')
    const duration    = ref(0)

    const initAnimation = () => {
      var width = window.innerWidth * 2
      duration.value = ( width / speed.value ) * 1000
    }

    onMounted(() => {
      if ( active.value ) {
        initAnimation()
      }
    })
    
    return () => slots.default({
      duration: duration.value
    })
  }
}
</script>