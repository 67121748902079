<script>
import { toRef, ref, onMounted } from 'vue'

export default {
  props: {
    video: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const videoId = toRef(props, 'video')
    const muted   = ref(true)
    const player  = ref(null)

    const toggleMute = () => {
      if ( player.value.muted ) {
        player.value.muted = false
        muted.value = false
      } else {
        player.value.muted = true
        muted.value = true
      }
    }

    onMounted(() => {
      player.value = document.getElementById( videoId.value )
    })

    return () => slots.default({
      muted,
      toggleMute
    })
  }
}
</script>