<script>
import { Autoplay, Mousewheel, Navigation, Pagination, FreeMode } from 'swiper'

export default {
  setup(props, { slots }) {
    return () => slots.default({
      modules: {
        autoplay: Autoplay,
        mousewheel: Mousewheel,
        navigation: Navigation,
        pagination: Pagination,
        freemode: FreeMode
      }
    })
  }
}
</script>