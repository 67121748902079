<script>
import { toRef, ref, onMounted } from 'vue'

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const link   = toRef(props, 'url')
    const vidID  = ref(null)
    const video  = ref(null)
    const player = ref(null)
    const muted  = ref(true)

    const getvidID  = ( url ) => {
      var regExp  = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      var match   = url.match( regExp )
      return ( match&&match[7].length==11 ) ? match[7] : false
    }

    const initYoutube = () => {
      if ( ! video.value ) {
        vidID.value = getvidID(link.value)
        video.value = new YT.Player( player.value, {
          height: '1920',
          width: '1080',
          vidID: vidID.value,
          playerVars: {
            'autoplay': 1,
            'mute': 1,
            'loop': 1,
            'disablekb': 1,
            'playsinline': 1,
            'enablejsapi': 1,
            'controls': 0,
            'showinfo': 0,
            'playlist': vidID.value
          }
        })
      }
    }

    const toggleMute = () => {
      if ( video.value.isMuted() ) {
        video.value.unMute()
        muted.value = false
      } else {
        video.value.mute()
        muted.value = true
      }
    }

    onMounted(() => {
      initYoutube()
    })

    window.onYouTubePlayerAPIReady = () => {
      initYoutube()
    }

    return () => slots.default({
      player,
      muted,
      toggleMute
    })
  }
}
</script>