/* global appSettings */
import { map, pick } from 'lodash'
import { defineStore } from 'pinia'
import { Buffer } from 'buffer'
import qs from 'qs'
import axios from 'axios'

const client_id     = '49f2946128f44858adc638edc71561cc'
const client_secret = '22a99c2daaff42efb647ca094a24e4ad'
const auth_token    = Buffer.from(`${client_id}:${client_secret}`, 'utf-8').toString('base64')
const spData        = qs.stringify({'grant_type':'client_credentials'})

const storeAJAX = axios.create({
  headers: { 'Access-Control-Allow-Origin' : '*' },
  baseURL: appSettings.site.url,
  timeout: 30000,
})

const spotifyHead = axios.create()

export const useStore = defineStore('main', {
  state: () => ({
    drawers: {
      nav: false,
      cart: false,
      search: false
    },
    cart: {
      items: [],
      item_count: 0
    }
  }),
  getters: {},
  actions: {
    async storeAJAX( args ) {
      const response = await storeAJAX(args)
      return response
    },
    async spotifyAuth() {
      const response = await axios.post('https://accounts.spotify.com/api/token', spData, {
        headers: { 
          'Authorization': `Basic ${auth_token}`,
          'Content-Type': 'application/x-www-form-urlencoded' 
        }
      })
      return response.data.access_token
    },
    async spotifyAPI( args ) {
      const spToken  = await this.spotifyAuth()
      args.headers   = { 'Authorization': `Bearer ${spToken}` }
      const response = await spotifyHead(args)
      return response
    },
    getCart() {
      const count = document.getElementById( 'cart_count' )
      axios.get(appSettings.site.url + '/cart.js')
      .then((response) => {
        this.cart = response.data
        count.innerHTML = response.data.item_count
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        
      })
    },
    htmlDecode(str) {
      var doc = new DOMParser().parseFromString(str, 'text/html')
      var dom = doc.documentElement.textContent
      var reg = new RegExp(String.fromCharCode(160), 'g')
      var str = dom.replaceAll(reg, ' ')
      return str
    },
    getHexCode(colours, name) {
      const words = name.toLowerCase().split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const title = words.join(' ')
      const hex   = map(pick(colours, [title]), o => o)
      return hex[0]
    },
    formatMoney(cents, format = "Rp {{amount_no_decimals}}") {
      if (typeof cents == 'string') { cents = cents.replace(',','') }
      var value = ''
      var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/
      var formatString = (format || this.money_format)
    
      function defaultOption(opt, def) {
          return (typeof opt == 'undefined' ? def : opt)
      }
    
      function formatWithDelimiters(number, precision, thousands, decimal) {
        precision = defaultOption(precision, 2)
        thousands = defaultOption(thousands, '.')
        decimal   = defaultOption(decimal, ',')
    
        if (isNaN(number) || number == null) { return 0 }
    
        number = (number/100.0).toFixed(precision)
    
        var parts   = number.split('.'),
            dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
            cents   = parts[1] ? (decimal + parts[1]) : ''
    
        return dollars + cents
      }
    
      switch(formatString.match(placeholderRegex)[1]) {
        case 'amount':
          value = formatWithDelimiters(cents, 2)
          break
        case 'amount_no_decimals':
          value = formatWithDelimiters(cents, 0)
          break
        case 'amount_with_comma_separator':
          value = formatWithDelimiters(cents, 2, '.', ',')
          break
        case 'amount_no_decimals_with_comma_separator':
          value = formatWithDelimiters(cents, 0, '.', ',')
          break
      }
    
      return formatString.replace(placeholderRegex, value)
    },
    filterParam(type, param) {
      if (type === 'color') {
        return param.includes('colour') || param.includes('color')
      } else if (type === 'size') {
        return param.includes('size')
      } else {
        return false
      }
    }
  }
})